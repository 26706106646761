import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const Aside = makeShortcode("Aside");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`We have years of experience working with rendering engines and creating beautiful and realistic renders for any industry.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <Row mdxType="Row">
Demonstrate vision and value proposition of your product through our rendering and visualization solutions.
        </Row>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHLipwWHg//xAAZEAEAAwEBAAAAAAAAAAAAAAABAAIDITH/2gAIAQEAAQUCdbsFi9PcjlKif//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAMAAwAAAAAAAAAAAAAAAAABIRAyQf/aAAgBAQAGPwLYrO5qP//EABoQAAMAAwEAAAAAAAAAAAAAAAABESExcYH/2gAIAQEAAT8hWb1jjVOUg1j0yQU6a2YqOOH/2gAMAwEAAgADAAAAEMfv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFRQWHR/9oACAEBAAE/ELjqaGUQaJe0RnuKK5fB9QAks6iQC4VL8xmoIF6n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f7f34c3828eb6e19f79ed6f5d6026365/5f1c3/leica.webp 288w", "/static/f7f34c3828eb6e19f79ed6f5d6026365/c4eb6/leica.webp 576w", "/static/f7f34c3828eb6e19f79ed6f5d6026365/c2ad5/leica.webp 1152w", "/static/f7f34c3828eb6e19f79ed6f5d6026365/077a8/leica.webp 1410w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f7f34c3828eb6e19f79ed6f5d6026365/58ca1/leica.jpg 288w", "/static/f7f34c3828eb6e19f79ed6f5d6026365/7fee0/leica.jpg 576w", "/static/f7f34c3828eb6e19f79ed6f5d6026365/914fe/leica.jpg 1152w", "/static/f7f34c3828eb6e19f79ed6f5d6026365/09ce5/leica.jpg 1410w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f7f34c3828eb6e19f79ed6f5d6026365/914fe/leica.jpg",
              "alt": "Photorealistic render - Magnus Code - Malaysia",
              "title": "Photorealistic render - Magnus Code - Malaysia",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
        <Caption fullWidth mdxType="Caption">Photorealistic render of Leica M9</Caption>
        <Row mdxType="Row">
  Our expertise in creating extreme scenarios and endless product variation
  allows you to showcase anything with various depth, exposure or aperture
  without having to create a prototype.
        </Row>
        <Row mdxType="Row">
  Magnus Code facilitates you to present photorealistic outputs through high
  quality image or animation, showcasing internal product component and
  functioning.
        </Row>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside aria-label="Example aside" mdxType="Aside">
          <p><strong parentName="p">{`Photorealistic renders.`}</strong></p>
          <p>{`We focus on creating high fidelity photorealistic renders. We have years of experience studying materials and lighting to ensure great renders for visualization and technical use cases.`}</p>
        </Aside>
      </Column>
    </Row>
    <Row className="resource-card-group mt-4 " mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Talk to our team" title="Contact Us" aspectRatio="2:1" actionIcon="arrowRight" href="/contact" color="light" className="blue-card" mdxType="ResourceCard"></ResourceCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      